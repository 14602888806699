import { useEffect, useState } from 'react'

const favicons = {
  default: `/favicon-crawford-192x192.png`,
  crawford: `/favicon-crawford-192x192.png`,
  normstahl: `/favicon-normstahl-192x192.png`,
}

const useFavicon = () => {
  const [company, setCompany] = useState('default')
  useEffect(() => {
    setCompany(
      new RegExp(/crawford/i, 'i').test(window.location.href)
        ? 'crawford'
        : 'normstahl'
    )
  }, [])
  return favicons[company] || favicons['default']
}

export default useFavicon
